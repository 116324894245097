import { fuelOptions } from "../constants/heating";
import { calculateHeatingInput } from './heating';
const weights = [0, 1000, 1600, 2200, 3200, 4200, 5200, 6500, 6500];
const energyLabelOrder = {
    A2020: 1,
    A2015: 2,
    A2010: 3,
    B: 4,
    C: 5,
    D: 6,
    E: 7,
    F: 8,
    G: 9,
};
const bounds = [
    { label: 'A2020', upper: 27 },
    { label: 'A2015', upper: 30 },
    { label: 'A2010', upper: 52.5 },
    { label: 'B', upper: 70 },
    { label: 'C', upper: 110 },
    { label: 'D', upper: 150 },
    { label: 'E', upper: 190 },
    { label: 'F', upper: 240 },
    { label: 'G', upper: 300 },
];
export const calculateEnergyLabel = (energyExpenditure, area, conversionType = 'fromFuel') => {
    const factor = getConversionFactor(conversionType);
    const expenditurePerArea = (energyExpenditure / area) * factor;
    const weightedBounds = bounds.map((value, index) => ({
        label: value.label,
        upper: value.upper + (weights[index] || 0) / area,
    }));
    const { label } = weightedBounds.find(({ upper }) => expenditurePerArea <= upper) ?? weightedBounds[weightedBounds.length - 1];
    return { label, bounds: weightedBounds };
};
export const calculateResidenceEnergyLabel = (residence, conversionType = 'default', usedFromSolarCells = 0, energyOptions = fuelOptions) => {
    const { primaryHeating, secondaryHeating, area } = residence;
    const primaryEnergy = calculateHeatingInputWithFactor(primaryHeating ?? undefined, energyOptions);
    const secondaryEnergy = calculateHeatingInputWithFactor(secondaryHeating ?? undefined, energyOptions);
    const energy = primaryEnergy + secondaryEnergy - usedFromSolarCells;
    return calculateEnergyLabel(energy, area, conversionType);
};
export const verifyEnergyLabel = (label, minimumLabel) => {
    const labelPosition = energyLabelOrder[label];
    const minimumLabelPosition = energyLabelOrder[minimumLabel];
    return labelPosition > minimumLabelPosition ? minimumLabel : label;
};
const calculateHeatingInputWithFactor = (heating, energyOptions = fuelOptions) => {
    const { fuelType, heatSource } = heating ?? {};
    const fuelFactor = getFuelFactor(fuelType, heatSource ?? undefined);
    return calculateHeatingInput(heating, energyOptions) * fuelFactor;
};
const getFuelFactor = (...fuelTypes) => {
    if (fuelTypes.includes('electricity')) {
        return 1.9;
    }
    return 1;
};
const getConversionFactor = (conversionType) => {
    switch (conversionType) {
        case 'fromDistrictHeating':
            return 0.85;
        case 'fromFuel':
            return 1.9;
        default:
            return 1;
    }
};
