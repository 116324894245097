/* eslint-disable @typescript-eslint/ban-ts-comment */
import mean from 'lodash/mean';
import { fuelOptions } from "../constants";
import { calculateElectricitySellPrice } from "../../util";
const electricityValueKeys = {
    electricity_discount: 'discount',
    discount_electricity_required: 'discountRequired',
    electricity_sell_price_reduction: 'sellPriceReduction',
    electricity_minimum_sell_price: 'minimumSellPrice',
};
const inflationValueKeys = {
    inflation_years: 'years',
    inflation_rate: 'rate',
};
export const fuelKeys = [
    'wood_1',
    'wood_2',
    'pallet',
    'districtHeating',
    'electricity',
    'oil',
    'gas',
    'wood',
    'straw',
    'districtHeating',
    'inflation',
];
const valueKeys = ['calorific', 'price', 'emission'];
export const mapConstantsToEnergyOptions = (energyConstants, electricityPrice, heatings = [], applyInflation = false) => {
    const energyOptions = energyConstants.reduce((options, constant) => {
        const { code, valueNumber, denominator } = constant;
        const fuelKey = fuelKeys.find(key => code.includes(key));
        if (!fuelKey) {
            return options;
        }
        if (['wood_1', 'wood_2'].includes(fuelKey)) {
            const volumeUnit = denominator;
            if (!volumeUnit) {
                return options;
            }
            const valueKey = valueKeys.find(key => code.includes(key));
            if (!valueKey) {
                return options;
            }
            return {
                ...options,
                [fuelKey]: {
                    ...options[fuelKey],
                    [volumeUnit]: {
                        // @ts-ignore
                        ...options[fuelKey]?.[volumeUnit],
                        [valueKey]: valueNumber,
                        ...(!code.includes('_emission') && denominator ? { denominator } : {}),
                    },
                },
            };
        }
        const electricityKey = electricityValueKeys[code];
        if (electricityKey) {
            return {
                ...options,
                electricity: {
                    ...options.electricity,
                    [electricityKey]: valueNumber,
                },
            };
        }
        const inflationKey = inflationValueKeys[code];
        if (inflationKey) {
            const value = applyInflation ? valueNumber : 0;
            return {
                ...options,
                inflation: {
                    ...options.inflation,
                    [inflationKey]: inflationKey === 'rate' ? value / 100 : value,
                },
            };
        }
        const valueKey = valueKeys.find(key => code.includes(key));
        if (!valueKey) {
            return options;
        }
        // Electricity fuelType should apply to all heating types using electricity
        // even though they don't have individual constant rows
        if (fuelKey === 'electricity') {
            const fuelValue = {
                ...options[fuelKey],
                [valueKey]: valueNumber,
                ...(!code.includes('_emission') && denominator ? { denominator } : {}),
            };
            return { ...options, [fuelKey]: fuelValue };
        }
        return {
            ...options,
            [fuelKey]: {
                ...options[fuelKey],
                [valueKey]: valueNumber,
                ...(!code.includes('_emission') && denominator ? { denominator } : {}),
            },
        };
    }, fuelOptions);
    return attachHeatingPricesToEnergyOptions(attachCustomerElectricityPriceToEnergyOptions(energyOptions, electricityPrice), heatings);
};
export const findConstantForHeating = (heating, energyOptions = fuelOptions) => {
    const fuelSubtype = heating?.fuelSubtype;
    const volumeUnit = heating?.volumeUnit;
    const fuelType = heating?.fuelType;
    const woodEnergyOption = energyOptions[fuelSubtype];
    if (woodEnergyOption && woodEnergyOption[volumeUnit]) {
        return woodEnergyOption[volumeUnit];
    }
    if (fuelType?.toLowerCase()?.includes('heatpump')) {
        return energyOptions['electricity'];
    }
    return energyOptions[fuelType];
};
export const attachCustomerElectricityPriceToEnergyOptions = (energyOptions, customerPrice) => {
    const { electricity } = energyOptions;
    const { price: defaultPrice, sellPriceReduction, minimumSellPrice } = electricity ?? {};
    const price = customerPrice ?? defaultPrice;
    const sellPrice = Math.max(calculateElectricitySellPrice(price ?? 0, sellPriceReduction ?? 0), minimumSellPrice ?? 0);
    return {
        ...energyOptions,
        electricity: { ...electricity, price, sellPrice },
    };
};
export const attachHeatingPricesToEnergyOptions = (energyOptions, heatings = []) => {
    if (heatings.length === 0) {
        return energyOptions;
    }
    const newOptions = JSON.parse(JSON.stringify(energyOptions));
    for (const heating of heatings) {
        const { fuelType, fuelSubtype, price, volumeUnit } = heating;
        const fuelKey = fuelType;
        const fuelSubtypeKey = fuelSubtype;
        if (!price || price < 0 || !fuelKey || fuelKey === 'electricity') {
            continue;
        }
        if (!fuelSubtype) {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            newOptions[fuelKey] = { ...energyOptions[fuelKey], price };
        }
        const volumeUnitKey = volumeUnit;
        if (!volumeUnitKey) {
            continue;
        }
        newOptions[fuelSubtypeKey] = {
            ...energyOptions[fuelSubtypeKey],
            [volumeUnitKey]: {
                ...(energyOptions[fuelSubtypeKey]?.[volumeUnitKey] ?? {}),
                price,
            },
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        };
    }
    return newOptions;
};
export const increaseFuelPrices = (energyOptions) => {
    const { straw, gas, oil, pallet, districtHeating, wood, wood_1, wood_2, electricity, inflation } = energyOptions;
    const { years, rate } = inflation;
    return {
        ...energyOptions,
        districtHeating: increasePricesForFuelType(districtHeating, years, rate),
        gas: increasePricesForFuelType(gas, years, rate),
        oil: increasePricesForFuelType(oil, years, rate),
        pallet: increasePricesForFuelType(pallet, years, rate),
        straw: increasePricesForFuelType(straw, years, rate),
        wood: increasePricesForFuelType(wood, years, rate),
        wood_1: {
            kasserummeter: increasePricesForFuelType(wood_1?.kasserummeter, years, rate),
            skovrummeter: increasePricesForFuelType(wood_1?.skovrummeter, years, rate),
        },
        wood_2: {
            kasserummeter: increasePricesForFuelType(wood_2?.kasserummeter, years, rate),
            skovrummeter: increasePricesForFuelType(wood_2?.skovrummeter, years, rate),
        },
        electricity: incresePricesForElectricity(electricity, years, rate),
    };
};
export const calculateAverageFuelPrices = (energyOptions) => {
    const energyOptionsWithUpdatedInflation = increaseFuelPrices(energyOptions);
    return {
        ...energyOptions,
        districtHeating: getEnergyOptionWithAveragePrice(energyOptions.districtHeating, energyOptionsWithUpdatedInflation.districtHeating),
        gas: getEnergyOptionWithAveragePrice(energyOptions.gas, energyOptionsWithUpdatedInflation.gas),
        oil: getEnergyOptionWithAveragePrice(energyOptions.oil, energyOptionsWithUpdatedInflation.oil),
        pallet: getEnergyOptionWithAveragePrice(energyOptions.pallet, energyOptionsWithUpdatedInflation.pallet),
        straw: getEnergyOptionWithAveragePrice(energyOptions.straw, energyOptionsWithUpdatedInflation.straw),
        wood: getEnergyOptionWithAveragePrice(energyOptions.wood, energyOptionsWithUpdatedInflation.wood),
        wood_1: {
            kasserummeter: getEnergyOptionWithAveragePrice(energyOptions.wood_1?.kasserummeter, energyOptionsWithUpdatedInflation.wood_1?.kasserummeter),
            skovrummeter: getEnergyOptionWithAveragePrice(energyOptions.wood_1?.skovrummeter, energyOptionsWithUpdatedInflation.wood_1?.skovrummeter),
        },
        wood_2: {
            kasserummeter: getEnergyOptionWithAveragePrice(energyOptions.wood_2?.skovrummeter, energyOptionsWithUpdatedInflation.wood_2?.skovrummeter),
            skovrummeter: getEnergyOptionWithAveragePrice(energyOptions.wood_2?.skovrummeter, energyOptionsWithUpdatedInflation.wood_2?.skovrummeter),
        },
        electricity: {
            ...energyOptions.electricity,
            price: mean([energyOptions.electricity.price, energyOptionsWithUpdatedInflation.electricity.price]),
            sellPrice: mean([energyOptions.electricity.sellPrice, energyOptionsWithUpdatedInflation.electricity.sellPrice]),
            minimumSellPrice: mean([
                energyOptions.electricity.minimumSellPrice,
                energyOptionsWithUpdatedInflation.electricity.minimumSellPrice,
            ]),
        },
    };
};
const getEnergyOptionWithAveragePrice = (startEnergyOption, finalEnergyOption) => startEnergyOption &&
    finalEnergyOption && {
    ...startEnergyOption,
    price: mean([startEnergyOption?.price, finalEnergyOption?.price]),
};
const increasePricesForFuelType = (fuelType, years = 1, inflationRate = 0.02) => {
    if (!fuelType) {
        return undefined;
    }
    const { price } = fuelType;
    return { ...fuelType, price: projectValueWithAnnualIncrease(price, years, inflationRate) };
};
const incresePricesForElectricity = (fuelType, years = 1, inflationRate = 0.02) => {
    const { price: oldPrice, sellPriceReduction, minimumSellPrice: oldMinimumSellPrice } = fuelType;
    const price = projectValueWithAnnualIncrease(oldPrice, years, inflationRate);
    const minimumSellPrice = oldMinimumSellPrice
        ? projectValueWithAnnualIncrease(oldMinimumSellPrice, years, inflationRate)
        : 0;
    return {
        ...fuelType,
        price,
        sellPriceReduction,
        sellPrice: Math.max(calculateElectricitySellPrice(price ?? 0, sellPriceReduction ?? 0), minimumSellPrice ?? 0),
        minimumSellPrice,
    };
};
const projectValueWithAnnualIncrease = (value, years = 1, inflationRate = 0.02) => value * Math.pow(1 + inflationRate, years);
