import sumBy from 'lodash/sumBy';

import { DeepPartial, NewOfferExpensesInput, Offer, OfferExpenseTemplate, SolarPanel } from '@src/model';

export const formatProductText = (product?: Pick<SolarPanel, 'brand' | 'model'>) => {
  const { brand = '', model = '' } = product ?? {};
  return `${brand} ${model}`.trim();
};

type OfferInstallationExpense = Pick<OfferExpenseTemplate, 'specialKey' | 'minimumProducts' | 'price'>;

export const findInstallationExpensesForOffer = (
  offer?: Pick<DeepPartial<Offer>, 'heatPumpOutdoorUnit' | 'solarPanel' | 'battery'>,
  installationExpenses?: { heatPump: OfferInstallationExpense[]; solar: OfferInstallationExpense[] }
) => {
  if (!offer || !installationExpenses) {
    return [];
  }

  const { heatPump, solar: solarExpenses } = installationExpenses;
  const { heatPumpOutdoorUnit, solarPanel, battery } = offer;

  if (!solarPanel) {
    return heatPump;
  }

  const { quantity = 0 } = solarPanel ?? {};

  const hasBatteries = !!battery;
  const solarSpecialKeys = new Set(['inverter_installation', ...(hasBatteries ? ['battery_installation'] : [])]);

  const solarPanelExpense = [...solarExpenses]
    .sort((a, b) => (b.minimumProducts ?? 0) - (a.minimumProducts ?? 0))
    .find(
      ({ minimumProducts = 0, specialKey }) =>
        quantity >= (minimumProducts ?? 0) && specialKey === 'solar_panel_installation'
    );

  const solar = [
    ...solarExpenses.filter(({ specialKey }) => solarSpecialKeys.has(specialKey ?? '')),
    ...(solarPanelExpense ? [{ ...solarPanelExpense, price: (solarPanelExpense.price ?? 0) * quantity }] : []),
  ];

  if (!heatPumpOutdoorUnit) {
    return solar;
  }

  return [...heatPump, ...solar];
};

export const newOfferPriceWithExpenses = (
  offer?: Pick<
    DeepPartial<Offer>,
    'heatPumpOutdoorUnit' | 'heatPumpIndoorUnit' | 'inverter' | 'solarPanel' | 'battery'
  >,
  expenses?: NewOfferExpensesInput
): number => {
  if (isEmptyOffer(offer)) {
    return 0;
  }

  const matchedExpenses = findInstallationExpensesForOffer(offer, expenses);
  const installationPrice = sumBy(matchedExpenses, 'price');

  const requiredPrice = sumBy(expenses?.required ?? [], 'price');
  const numberOfOffers = offer?.heatPumpOutdoorUnit && offer?.solarPanel ? 2 : 1;

  return installationPrice + numberOfOffers * requiredPrice;
};

const isEmptyOffer = (offer?: DeepPartial<Offer>): boolean =>
  [offer?.heatPumpIndoorUnit, offer?.heatPumpOutdoorUnit, offer?.battery, offer?.inverter, offer?.solarPanel].filter(
    product => !!product
  ).length === 0;
